






import { Mixins, Component } from 'vue-property-decorator'
import PermissionsMixin from 'src/mixins/permissions-mixin'
import { CurrentUser } from 'src/types/models/index'

@Component
export default class App extends Mixins(PermissionsMixin) {
  created() {
    this.refreshSession()
  }

  refreshSession() {
    const hour = 60 * 60 * 1000
    const currentUser: CurrentUser | null = this.$q?.localStorage.getItem(
      'user'
    )
    try {
      setInterval(async () => {
        if (currentUser) {
          const cognitoUser = await this.$Auth.currentAuthenticatedUser()
          const currentSession = cognitoUser.signInUserSession
          cognitoUser.refreshSession(currentSession.refreshToken, () => '')
        }
      }, hour)
    } catch (e) {
      console.log('Unable to refresh Token', e)
    }
  }
}
