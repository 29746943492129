export default {
  header_title: 'ConDoor dashboard',
  submit: 'Submit',
  username: 'Username',
  footer: 'Copyright © Synadia. All Rights Reserved',
  no_data: 'No data available',
  agree: 'Agree',
  auth: {
    sign_in: 'Sign In',
    reset_pass: 'Reset password',
    back_login: 'Back to Log in',
    resend_code: 'Resend Code',
    new_pass: 'New Password',
    confirm_pass: 'Confirm Password',
    confirm_code: 'Verification code',
    change_pass: 'Change Password',
    pass: 'Password',
    email_phone: 'Email or phone number',
    save_creds: 'Save Credentials',
    cookies:
      'This website uses cookies. By accepting this website you consent to our use of cookies'
  },
  errors: {
    404: '404',
    error_page_title: 'Oops. Nothing here...',
    required: 'Field is required',
    to_long: 'Field is too long',
    to_short: 'Field is too short',
    whitespaces: 'Field contains only whitespaces',
    email: 'E-mail must be valid',
    pass_short: 'Password is too short',
    pass_number: 'Password should contain at least 1 number',
    pass_uppercase: 'Password should contain at least 1 uppercase letter',
    pass_lowercase: 'Password should contain at least 1 lowercase letter',
    code_mismatch: 'Provided code does not match what the server was expecting',
    user_not_found: 'User not found or it was disabled',
    code_expired: 'Code has expired',
    not_authorized: 'The user is not authorized',
    username_taken: 'This username already exists in the user pool',
    failed_req: 'Request failed. Please try again',
    local_storage: "Data wasn't saved to local storage. Please try again",
    req_user: 'Couldnt fetch user data. Please try again',
    sign_in: 'Failed to sign in. Please try again',
    cookies: 'Please accept our cookie statement before you sign in',
    failed_redirect: 'You don`t have permissions to visit this page'
  }
}
