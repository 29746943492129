const aws = {
  aws_project_region : 'eu-west-1',
  aws_cognito_identity_pool_id: 'eu-west-1:a68733e5-35a3-4514-a3aa-bf75dc6ae4bd',
  aws_cognito_region: 'eu-west-1',
  aws_user_pools_id:'eu-west-1_xC3Rr53MV',
  aws_user_pools_web_client_id: '4t03lcvl8jpnerocf1vh5617n3',
  aws_appsync_graphqlEndpoint: 'https://qeugfy4f4vfuzaetgbtwm7yem4.appsync-api.eu-west-1.amazonaws.com/graphql',
  aws_appsync_portal_graphqlEndpoint: 'https://bkewcq7cjnfprd5qzic5iyps5a.appsync-api.eu-west-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-west-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: null,
  oauth: {},
  Auth: {
    identityPoolId: 'eu-west-1:a68733e5-35a3-4514-a3aa-bf75dc6ae4bd',
    region: 'eu-west-1',
    userPoolId: 'eu-west-1_xC3Rr53MV',
    userPoolWebClientId: '4t03lcvl8jpnerocf1vh5617n3'
  }
}
export default aws
